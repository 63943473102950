import { YouTube } from '../_vendor/youtube/models/youtube';

declare global {
  interface Window {
    Cookiebot: any;
    JVM: {
      YouTube: YouTube | null;
      BlockMediaScriptIsInitialized: boolean;
    };
    YT: any;
    onYouTubeIframeAPIReady: Function;
    iframeLoadedHandler: Function;
    CookiebotCallback_OnLoad: Function;
  }
}


// Set global variables
if (!window.JVM) {
  window.JVM = {
    YouTube: null,
    BlockMediaScriptIsInitialized: false
  }
}

export class General {
  constructor() {}
  
  static loadImage(imageElement: HTMLImageElement): Promise<HTMLImageElement> {
    return new Promise((resolve, reject) => {
      imageElement.onload = () => {
        resolve(imageElement);
      };

      if (imageElement.complete) {
        resolve(imageElement);
      }

      imageElement.onerror = reject
    });
  }
}

window.addEventListener('CookiebotOnLoad', () => {
  if (!document.body.classList.contains("cookiebot-loaded")) {
    document.body.classList.add("cookiebot-loaded");
  }
});